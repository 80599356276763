<template>
  <div class="page">
    <div class="award">
      <img
        class="award_head"
        src="@/assets/lottery/tjf_cj_cjxq_jg_top_bg_img.png"
        alt=""
      />

      <div class="award_content">
        <!-- 一等奖 -->
        <template v-if="prize.prize_type === 1">
          <img
            class="title_img"
            src="@/assets/lottery/tjf_cj_cjxq_jg_zjl_img-min.png"
            alt=""
          />
          <div class="award_desc">
            <div class="award_level">
              {{ prize.level == 1 ? "一" : prize.level == 2 ? "二" : "三" }}等奖
            </div>
            <div class="award_title">
              {{ prize.title }}
            </div>
            <img class="award_img" :src="prize.image" alt="" />
          </div>
          <div class="address btn" @click="_golink">立即领取</div>
          <div class="tips">填写地址后48小时内发货请耐心等候</div>
        </template>

        <!-- 二等奖 -->
        <template v-else-if="prize.prize_type === 2">
          <img
            class="title_img"
            src="@/assets/lottery/tjf_cj_cjxq_jg_zjl_img-min.png"
            alt=""
          />
          <div class="award_desc">
            <div class="award_level">
              {{ prize.level == 1 ? "一" : prize.level == 2 ? "二" : "三" }}等奖
            </div>
            <div class="award_title">
              {{ prize.title }}
            </div>
            <img class="award_img second" :src="prize.image" alt="" />
          </div>
          <div class="address btn" @click="_golink">立即领取</div>
          <div class="tips">
            请于<span>中奖当日</span>领取奖品，逾期领取视为放弃奖品
          </div>
        </template>

        <!-- 三等奖 -->
        <template v-else-if="prize.prize_type === 3">
          <img
            class="title_img"
            src="@/assets/lottery/tjf_cj_cjxq_jg_zjl_img-min.png"
            alt=""
          />
          <div class="award_desc">
            <div class="award_level">
              {{ prize.level == 1 ? "一" : prize.level == 2 ? "二" : "三" }}等奖
            </div>
            <div class="award_title">
              {{ prize.title }}
            </div>
          </div>
          <div class="award_num" @click="_golink">
            ¥<span>{{ prize.value }}</span>
          </div>
        </template>

        <!-- 未中奖 -->
        <template v-else-if="prize.level === -1">
          <span class="title">很遗憾，这次没中奖</span>
          <span class="desc">不过别失望，前往瓜分红包领取更多奖励吧</span>
          <div class="go btn" @click="_golink">立即前往</div>
        </template>
      </div>
    </div>

    <van-overlay :show="show" @click="show = false">
      <self-mask></self-mask>
    </van-overlay>
  </div>
</template>

<script>
import Vue from "vue";
import {
  isMicroMessenger,
  isAndroid,
  isIOS,
  isMobile,
} from "@/utils/user-agent";
import { Overlay } from "vant";
import { LotteryGetPrizeItem } from "@/api/lottery";
import SelfMask from "@/views/alipage/ToTaoBao";
Vue.use(Overlay);
export default {
  components: {
    SelfMask,
  },

  data() {
    return {
      id: null,
      prize: {},
      show: false,
    };
  },
  created() {
    this.id = this.$route.params.id;
    this.LotteryGetPrizeItem();
  },

  methods: {
    //获取本次抽中的奖品
    LotteryGetPrizeItem() {
      LotteryGetPrizeItem({
        id: this.id,
      }).then((res) => {
        if (res.errCode === 0) {
          this.prize = res.data;
        }
      });
    },

    //去下载
    _golink() {
      if (isIOS) {
        var ios_link =
          "https://apps.apple.com/cn/app/%E6%B7%98%E8%A1%97%E5%9D%8A/id1444751745";
        window.location.href = ios_link;
        return "ios";
      } else {
        // 安卓微信浏览器需要在默认浏览器打开
        // if (isMicroMessenger) {
        //   this.show = true;
        //   return false;
        // }

        var andriod_link =
          "https://a.app.qq.com/o/simple.jsp?pkgname=com.quanminhuyu.taojiefang";
        window.location.href = andriod_link;
        return "andriod";
      }
    },
  },
};
</script>

<style lang="less">
.page {
  background: #ff6e3c;
  line-height: 1;
}

.award {
  margin: 10px 10px 0;

  &_head {
    display: block;
    width: 355px;
    height: 18px;
  }

  &_content {
    margin: -1px 10px;
    width: 335px;
    padding-bottom: 50px;
    background: #fffcf2;
    border-radius: 0 0 10px 10px;
    display: flex;
    flex-direction: column;
    align-items: center;

    // 未中奖
    .title {
      margin: 63px 0 26px;
      font-size: 24px;
      font-weight: 600;
      color: #903e16;
    }

    .desc {
      margin-bottom: 48px;
      font-size: 15px;
      font-weight: 400;
      color: #9e6144;
    }

    .btn {
      width: 290px;
      height: 45px;
      line-height: 45px;
      text-align: center;
      background: #ffd23f;
      box-shadow: 0 5px 0 0 #ffbd3d;
      border-radius: 22.5px;
      font-size: 18px;
      font-weight: 600;
      color: #bc1200;
    }

    .next {
      margin-top: 20px;
      box-shadow: 0 5px 0 0 #ff682d;
      background: #ff871f;
      color: #ffffff;
    }

    // 中奖了
    .title_img {
      margin: 35px 0 21px;
      display: block;
      width: 191px;
      height: 26px;
    }

    .award_desc {
      margin-bottom: 35px;
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 260px;
      background: #ff763e;
      border-radius: 10px;
      border: 5px solid #ffbf7a;
      padding: 22px 39px 25px;
      box-sizing: border-box;

      .award_level {
        width: 90px;
        height: 26px;
        line-height: 26px;
        text-align: center;
        background: #f25b1d;
        border-radius: 13px;
        font-size: 17px;
        font-weight: 400;
        color: #ffeebb;
      }

      .award_title {
        text-align: center;
        font-size: 18px;
        font-weight: 600;
        color: #ffeebb;
        margin-top: 14px;
      }

      .award_img {
        margin-top: 20px;
        width: 158px;
        height: 158px;
        border-radius: 14px;
        border: 6px solid rgba(255, 252, 242, 0.4);
      }

      .second {
        width: 102px;
        height: 109px;
        border: 15px solid rgba(255, 252, 242, 0.4);
      }
    }

    .award_num {
      text-align: center;
      width: 200px;
      height: 269px;
      background: url("../../assets/lottery/tjf_cj_cjxq_jg_gfhb_dkhb_img.png")
        no-repeat;
      background-size: 100% 100%;
      padding-top: 40px;
      box-sizing: border-box;
      font-size: 34px;
      font-weight: 600;
      color: #fc3e30;

      span {
        position: relative;
        top: 2px;
        margin-left: 7px;
        font-size: 54px;
      }
    }

    .tips {
      margin-top: 25px;
      font-size: 15px;
      font-weight: 400;
      color: #9a5839;

      span {
        color: #ff5014;
      }
    }
  }
}
</style>
