//抽奖详情
export const LotteryItem = (params) => window._http('/Lottery-item', params)

//获取抽奖参加的用户列表
export const LotteryGetJoinedUsers = (params) => window._http('/Lottery-getJoinedUsers', params)

//参加抽奖
export const LotteryJoin = (params) => window._http('/Lottery-join', params)

//获取本次抽中的奖品
export const LotteryGetPrizeItem = (params) => window._http('/Lottery-getPrizeItem', params)
